import React from "react";

const Error404 = () => {

  return (
    <div style={{
      width: '100%',
      padding: '32px',
      height: '100%',
      display: 'flex',
      position: 'relative',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column'
    }}>
      <span style={{
        fontSize: '2.125em',
        fontWeight: 'bold',
        lineHeight: '1.53',
        color: '#010101',
        margin: '0',
        marginBottom: '10px',
        fontFamily: "'Roboto', sans-serif",
        marginTop: '16px'
      }}>Ой, ошибка 404!</span>
      <img style={{width: 'auto', height: '35%'}} src='/static/images/404.png' alt=""/>
    </div>
  )
}

export default Error404;
